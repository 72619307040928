export default {
    github: {
        apiBaseURL: 'https://api.github.com',
    },
    cache: {
        apiBaseURL: 'https://api.gitcompare.com/v1',
        cacheExpiryTime: 1000 * 60,
    },
    npm: {
        apiBaseURL: 'https://api.npmjs.org',
    },
};  