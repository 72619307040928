import React from 'react';
import { Table, TableBody } from '@material-ui/core';
import { SortableTable, SortableTableWrapper } from '../SortableTable';
import PackagesBadgesTableRow from './PackagesBadgesTableRow';
import { sortPackages } from '../../utils';

export default class PackagesBadgesTable extends SortableTable {
	constructor(props, context) {
		super(props, context);

		this.state = {
			order: 'desc',
			orderBy: 'name',
			columns: [
        { id: 'name', numeric: false, disablePadding: true, label: 'Name', icon: '🆔' },
        { id: 'badges', numeric: false, disablePadding: true, label: 'Badges', icon: '🔖' },
			],
			data: [],
		};
	}

  static getDerivedStateFromProps(nextProps, prevState) {
		const { orderBy, order } = prevState;
    const propsData = nextProps.packages
    .filter(currentPackage => (!!currentPackage.general.data && currentPackage.meta.data.shields.length > 0))
    .map(currentPackage => {
          const { full_name } = currentPackage.general.data;
          const { name } = currentPackage;
          
          // Set shields
          const shields = currentPackage.meta.isLoaded ? {
            values: currentPackage.meta.data.shields,
            isLoading: currentPackage.meta.isLoading,
          } : {
            values: null,
            isLoading: currentPackage.meta.isLoading,
          };

          return {
              id: full_name,
              name,
              shields,
          };
    });

    const data = sortPackages(propsData, orderBy, order);

    return {
      ...prevState,
      data,
    };
  }

	render() {
		const { data } = this.state;
		if (data.length === 0) return null;

		return (
			<SortableTableWrapper title="Badges" subtitle="Coverage and Build Information">
				<Table>
					<TableBody>
						{data.map(currentPackage => <PackagesBadgesTableRow key={currentPackage.id} package={currentPackage} />)}
					</TableBody>
				</Table>
			</SortableTableWrapper>
		);
	}
}

