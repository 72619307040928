import React, { Component } from 'react';
import * as QueryString from 'query-string';
import { connect } from 'react-redux';

import { 
    addPackage, 
    removePackage, 
    getPackagePulls, 
    getPackageCommits, 
    getPackageContributors, 
    getPackageActivity, 
    getPackagesDownloads,
    getPackageDownloads,
} from '../store/actions/packages';

import { getPackagesIdsFromQSParams  } from '../utils';

import PackagesChips from '../components/PackagesChips';
import PageSpinner from '../components/PageSpinner';
import NoPackages from '../components/NoPackages';
import { PackagesGeneralTable } from '../components/PackagesGeneralTable';
import { PackagesBadgesTable } from '../components/PackagesBadgesTable';
import { PackagesActivityTable } from '../components/PackagesActivityTable';
import PackagesActivityChart from '../components/Charts/PackagesActivityChart';
import PackagesDownloadsChart from '../components/Charts/PackagesDownloadsChart';
import PackagesSummaryChart from '../components/Charts/PackagesSummaryChart/PackagesSummaryChart';

function getDocumentTitle(paramsPackages) {
    let output = 'GitCompare - ';

    if (paramsPackages.length > 0) {
        output += paramsPackages
        .map(paramsPackage  => { 
            const packageNameArr = paramsPackage.split('/');
            if (packageNameArr[1] && packageNameArr[1] !== '') {
                return packageNameArr[1].charAt(0).toUpperCase() + packageNameArr[1].slice(1);
            }
             
            return '';
        }).join(' vs. ');
    } else {
        output += 'Create a New Report';
    }

    return output;
}

function getDocumentDescription(paramsPackages) {
    if (paramsPackages.length > 0) {
        let output = 'A report of key open-source health metrics, featuring '
        output += paramsPackages
        .map(paramsPackage  => { 
            const packageNameArr = paramsPackage.split('/');
            if (packageNameArr[1] && packageNameArr[1] !== '') {
                return packageNameArr[1].charAt(0).toUpperCase() + packageNameArr[1].slice(1);
            }
             
            return '';
        }).join(' vs. ');

        output += '.';
        return output;
    }
        
    return 'Create a new report with packages you\'d like to compare.';
}

class Report extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    
    removePackage = (packageId) => {
        try {
            const packages = getPackagesIdsFromQSParams(this.props)
                            .filter(lookupPackageId => lookupPackageId !== packageId.toLowerCase());

            const search = `?${QueryString.stringify({ packages })}`;
            this.props.history.push({ pathname: '', search });
        } catch (err) {
            console.error(err);
        }
    }

    static async getDerivedStateFromProps(nextProps) {
        try {
            const { packages, removePackage, addPackage, getPackagePulls, getPackageCommits, getPackageContributors, getPackageActivity, getPackagesDownloads, getPackageDownloads } = nextProps;
            const paramsPackages = getPackagesIdsFromQSParams(nextProps);
            
            document.title = getDocumentTitle(paramsPackages);
            document.head.querySelector("[name=description]").content = getDocumentDescription(paramsPackages); 

            // Add non-existing packges
            paramsPackages.forEach(async packageId => {
                if (!packages.find(lookupPackage => lookupPackage.get('id') === packageId)) {
                    try {
                        const addPackageCall = await addPackage(packageId);

                        if (addPackageCall.data && !(addPackageCall.data.fromLocalCache || addPackageCall.data.fromRemoteCache)) {
                            await getPackagePulls(packageId);
                            await getPackageCommits(packageId);
                            await getPackageContributors(packageId);
                            await getPackageActivity(packageId);
                        }
                    } catch (err) {
                        console.error(err);
                    }
                }
            });

            // Remove existing packages
            packages.forEach(lookupPackage => {
                if (!paramsPackages.includes(lookupPackage.get('id'))) {
                    removePackage(lookupPackage.get('id'));
                }
            });
            
            // Download all new packages downloads information
            if (paramsPackages.length === packages.size) {
                // Get Downloads Data
                const numberOfPackagesWithMetaData = packages.filter(currentPackage => {
                    return (currentPackage.getIn(['meta', 'isLoaded']));
                });

                if (paramsPackages.length === numberOfPackagesWithMetaData.size) {
                    const packagesForDownload = packages.map(currentPackage =>  {
                        const id = currentPackage.get('id');
                        const hasGeneralData = !!currentPackage.getIn(['general', 'data']);
                        const hasMetaData = !!currentPackage.getIn(['meta', 'data']);
                        const hasDownloadsData = (currentPackage.getIn(['downloads', 'isLoaded']) || currentPackage.getIn(['downloads', 'isLoading']));

                        if (!hasDownloadsData && hasGeneralData && hasMetaData) {
                            const metadata = currentPackage.getIn(['meta', 'data', 'package']);
                        
                            if (metadata) {
                                const name = metadata.get('name');
                                const confidence = metadata.get('confidence');
        
                                if (confidence > 0.5) return {
                                    id,
                                    name,
                                };;
                            }
                        }
        
                        return {
                            id,
                            name: null,
                        };
                    }).filter(currentPackage => (!!currentPackage.name));
                    
                    const packagesWithBulkDownloadInformation = packagesForDownload.filter(currentPackage => currentPackage.name.indexOf('/') === -1);
                    const packagesWithoutBulkDownloadInformation = packagesForDownload.filter(currentPackage => currentPackage.name.indexOf('/') !== -1);
                    
                    if (packagesWithBulkDownloadInformation.size > 1) getPackagesDownloads(packagesWithBulkDownloadInformation);
                    else if (packagesWithBulkDownloadInformation.size > 0) getPackageDownloads(packagesWithBulkDownloadInformation.get(0));
                    for (let currentPackage of packagesWithoutBulkDownloadInformation) getPackageDownloads(currentPackage);
                }
            }

            return null;
        } catch(err) {
            return null;
        }
    }



    render() {
        const packages = this.props.packages.toJS();
        const showPageSpinner = packages.length > 0 && !packages.find(currentPackage => !!currentPackage.general.data);
        const showNoPackages = packages.length === 0;

        return (
            <section>
                <PackagesChips removePackage={this.removePackage} packages={packages} />
                <PackagesGeneralTable packages={packages} />
                <PackagesBadgesTable packages={packages} />
                <PackagesActivityTable packages={packages} />
                <PackagesActivityChart packages={packages} />
                <PackagesDownloadsChart packages={packages} />
                <PackagesSummaryChart packages={packages} />
                {showPageSpinner && <PageSpinner />}
                {showNoPackages && <NoPackages />}
            </section>
        );
    }
}

export default connect((state) => ({ packages: state.packages }), { addPackage, removePackage, getPackagePulls, getPackageCommits, getPackageContributors, getPackageActivity, getPackageDownloads, getPackagesDownloads })(Report);
