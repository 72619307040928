import getRoundedScore from './getRoundedScore';

export default function getActivityScore(currentPackage, packages) {
    let packagesTotalCommits = 0;
    let packagesTotalForks = 0;
    let packagesTotalActivity = 0;
    let packagesTotalTimeFromLastCommit = 0;

    packages.filter(nextPackage => {
        return (nextPackage.general.isLoaded && nextPackage.commits.isLoaded && nextPackage.activity.isLoaded);
    }).forEach(nextPackage => {
        let packageTotalActivity = 0;
        if (nextPackage.activity.isLoaded) {
            for (let i = nextPackage.activity.data.length - 1; i > nextPackage.activity.data.length - 31 && i >= 0; i--) {
                packageTotalActivity += nextPackage.activity.data[i].total;
            }
        }
        
        // Add to totals
        packagesTotalCommits += nextPackage.commits.total;
        packagesTotalForks += nextPackage.general.data.forks;
        packagesTotalActivity += packageTotalActivity;
        packagesTotalTimeFromLastCommit += (new Date().getTime() - nextPackage.commits.lastCommitDate);
    });

    let currentPackageTotalActivity = 0;
    if (currentPackage.activity.isLoaded) {
        for (let i = currentPackage.activity.data.length - 1; i > currentPackage.activity.data.length - 31 && i >= 0; i--) {
            currentPackageTotalActivity += currentPackage.activity.data[i].total;
        }
    }
    
    const packageRelativeTimeFromLastCommit = (((new Date().getTime() - currentPackage.commits.lastCommitDate) / packagesTotalTimeFromLastCommit));
    const packageRelativeActivity = currentPackageTotalActivity / packagesTotalActivity;
    const packageRelativeCommits = currentPackage.commits.total / packagesTotalCommits;
    const packageRelativeForks = currentPackage.general.data.forks / packagesTotalForks;
    const roundedScore = getRoundedScore(100 * (packageRelativeActivity + packageRelativeCommits + packageRelativeForks) / 3, -1) + (1 - packageRelativeTimeFromLastCommit) * 3;

    return roundedScore;
}