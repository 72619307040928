import getRoundedScore from './getRoundedScore';

export default function getEngagementScore(currentPackage, packages) {
    let packagesTotalStars = 0;
    let packagesTotalWatchers = 0;
    let packagesTotalForks = 0;
    let packagesTotalIssues = 0;
    let packagesTotalPulls = 0;
    let packagesTotalContributors = 0;

    packages.filter(nextPackage => {
        return (nextPackage.general.isLoaded && nextPackage.issues.isLoaded && nextPackage.contributors.isLoaded && currentPackage.pulls.isLoaded);
    }).forEach(nextPackage => {
        packagesTotalStars += nextPackage.general.data.stargazers_count;
        packagesTotalWatchers += nextPackage.general.data.subscribers_count;
        packagesTotalForks += nextPackage.general.data.forks;
        packagesTotalContributors += nextPackage.contributors.total;
        packagesTotalIssues += nextPackage.issues.total;
        packagesTotalPulls += nextPackage.pulls.total;
    });
    
    const packageRelativeStars = currentPackage.general.data.stargazers_count / packagesTotalStars;
    const packageRelativeWatchers = currentPackage.general.data.subscribers_count / packagesTotalWatchers;
    const packageRelativeForks = currentPackage.general.data.forks / packagesTotalForks;
    const packageRelativeContributors = currentPackage.contributors.total / packagesTotalContributors;
    const packageRelativeIssues = currentPackage.issues.total / packagesTotalIssues;
    const packageRelativePulls = currentPackage.pulls.total / packagesTotalPulls;
    const averageScore = (packageRelativeStars * 0.1 + 
                          packageRelativeWatchers * 0.1 + 
                          packageRelativeForks * 0.1 + 
                          packageRelativeIssues * 0.1 + 
                          packageRelativePulls * 0.2 + 
                          packageRelativeContributors * 0.4);
    const roundedScore = getRoundedScore(100 * averageScore, -1);
    
    return roundedScore;
}