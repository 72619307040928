import {
    ADD_PACKAGE_STARTED,
    ADD_PACKAGE_SUCCESS,
    ADD_PACKAGE_FAIL,
} from '../../types/packages';

import { getPackage } from '../../../api/cache';
import github from '../../../api/github';

export default function addPackage(packageId) {
    return async (dispatch, getState) => {
        dispatch({ type: ADD_PACKAGE_STARTED, packageId })

        try {
            // First try to get package information from local storage 
            let cachedData;

            // Then try to get the package information from remote cache
            const cacheAPICall = await getPackage(packageId);
            if (cacheAPICall.status === 200) {
                cachedData = await cacheAPICall.json();
                cachedData.fromRemoteCache = true;
                dispatch({ type: ADD_PACKAGE_SUCCESS, packageId, cachedData });
                return { data: cachedData };
            }

            // Finally make a call to github's API to get package information
            const apiCall = await github.getRepository(packageId);
            const data = await apiCall.json();
            
            if (apiCall.status === 200) {
                dispatch({ type: ADD_PACKAGE_SUCCESS, packageId, data });
                return { data };
            } else {
                dispatch({ type: ADD_PACKAGE_FAIL, packageId, errors: [data] });
                return { errors: [data] };
            }
        } catch (err) {
            dispatch({ type: ADD_PACKAGE_FAIL, packageId, errors: [err] });
            return { errors: [err] };
        }
    }
}