import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import HeroImage from '../assets/img/hero-image.jpg';
import Hidden from '@material-ui/core/Hidden';

const styles = theme => ({
	root: {
        borderBottom: '1px solid #DDD',
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        paddingTop: 20,
        paddingBottom: 20,
    },
    heroText: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            alignItems: 'flex-start',
        },
    },
    title: {
        marginBottom: theme.spacing.unit * 2,
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
        },
    },
    subtitle: {
        marginBottom: theme.spacing.unit * 4,
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
        },
    },
    imageContainer: {
        lineHeight: 0,
        position: 'relative',
        padding: '50%',
        height: 0,
        overflow: 'hidden',
    },
    image: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
    },
});

class Hero extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    render() {
        const { classes } = this.props;

        return (
			<Paper className={classes.root} elevation={0}>
                <Grid container spacing={16} justify="center">
                    <Grid item xs={12} sm={12} md={8} className={classes.heroText}>
                        <Typography variant="headline" className={classes.title}>
                            Discover alternatives, take better decisions.
                        </Typography>
                        <Typography variant="subheading" color="textSecondary" className={classes.subtitle}>
                            GitCompare helps you compare and analyze open-source repositories. <br/>
                            Visit one of our curated reports or use our search bar and generate a new report with respositories related to your projects.
                        </Typography>
                        <Button className={classes.button} to="faq" component={Link} variant="contained" color="primary">
                            Find Out More
                        </Button>
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={12} sm={12} md={4}>
                            <div className={classes.imageContainer}>
                                <img className={classes.image} src={HeroImage} alt="Git Compare" />
                            </div>
                        </Grid>
                    </Hidden>
                </Grid>
            </Paper>
        );
    }
}

export default withStyles(styles)(Hero);