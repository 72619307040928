import React from 'react';

export default class SortableTable extends React.Component {
	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}
		
		this.setState({ order, orderBy });
	};
}