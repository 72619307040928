import React, { PureComponent } from 'react';
import InformationWrapper from '../../InformationWrapper';
import {
    getMaintenanceScore,
    getEngagementScore,
    getCommunityScore,
    getActivityScore,
    getAdoptionScore,
} from '../../../utils/summary';
import PackageSummaryChart from './PackageSummaryChart';
import { Link } from 'react-router-dom'

function extractSummaryData(packages) {
    // Check if all packages data was downloaded
    const allPackagesHasDownloads = packages.every(currentPackage => !!currentPackage.downloads.data);

    return packages.filter(currentPackage => {
        return currentPackage.general.isLoaded && 
               currentPackage.commits.isLoaded && 
               currentPackage.contributors.isLoaded && 
               currentPackage.pulls.isLoaded && 
               currentPackage.activity.isLoaded;
    }).map(currentPackage => {
        const output = {
            id: currentPackage.id,
            name: currentPackage.name,
            data: [{
                topic: 'Engagement', 
                score: getEngagementScore(currentPackage, packages),
                domain: [0, 10],
            }, {
                topic: 'Community', 
                score: getCommunityScore(currentPackage, packages),
                domain: [0, 10],
            }, {
                topic: 'Maintenance', 
                score: getMaintenanceScore(currentPackage, packages),
                domain: [0, 10],
            }, {
                topic: 'Activity', 
                score: getActivityScore(currentPackage, packages),
                domain: [0, 10],
            }],
        };

        if(allPackagesHasDownloads) {
            output.data.push({
                topic: 'Adoption', 
                score: getAdoptionScore(currentPackage, packages),
                domain: [0, 10],
            });
        }

        return output;
    });
}

class PackagesSummaryChart extends PureComponent {
    renderSubtitle() {
        return(
            <span>
                Our Relative Summary Comparison <Link to="/faq">(Learn More)</Link>
            </span>
        );
    }

    render() {
        const { packages } = this.props;
        if (packages.length <= 1) return null;

        const summaryData = extractSummaryData(packages);
        if (summaryData.length === 0) return null;

        return (
            <InformationWrapper title="Summary" subtitle={this.renderSubtitle()}> 
                {summaryData.map((packageSummaryData, idx) => (
                    <PackageSummaryChart key={packageSummaryData.id} packageSummaryData={packageSummaryData} />
                ))}
            </InformationWrapper>
        );
    }
}

export default PackagesSummaryChart;
