import {
    GET_PACKAGE_ACTIVITY_STARTED,
    GET_PACKAGE_ACTIVITY_SUCCESS,
    GET_PACKAGE_ACTIVITY_FAIL,

} from '../../types/packages';

export default function getPackageActivity(state, action) {
    let currentPackageIndex;

    switch (action.type) {
        case GET_PACKAGE_ACTIVITY_STARTED:
            currentPackageIndex = state.findIndex(lookupPackage => lookupPackage.get('id') === action.packageId);
            
            if (currentPackageIndex >= 0) {
                return state.setIn([currentPackageIndex, 'activity', 'isLoading'], true);
            }

            return state;
        case GET_PACKAGE_ACTIVITY_SUCCESS:
            currentPackageIndex = state.findIndex(lookupPackage => lookupPackage.get('id') === action.packageId);
            
            if (currentPackageIndex >= 0) {
                // Remove days information
                action.data.forEach(weeklyData => delete weeklyData.days);

                // Save the state
                return state.setIn([currentPackageIndex, 'activity', 'isLoading'], false)
                            .setIn([currentPackageIndex, 'activity', 'isLoaded'], true)
                            .setIn([currentPackageIndex, 'activity', 'data'], action.data);
            }

            return state;
        case GET_PACKAGE_ACTIVITY_FAIL:
            currentPackageIndex = state.findIndex(lookupPackage => lookupPackage.get('id') === action.packageId);
            
            if (currentPackageIndex >= 0) {
                return state.setIn([currentPackageIndex, 'activity', 'isLoading'], false)
                            .setIn([currentPackageIndex, 'activity', 'errors'], action.errors);
            }

            return state;
        default:
            return state;
    }
}