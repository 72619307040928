import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
	root: {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
	},
	tableWrapper: {
		overflowX: 'auto',
	},
	header: {
		margin: theme.spacing.unit * 4,
	},
});

class SortableTableWrapper extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string,
        classes: PropTypes.object.isRequired,
        children: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.node),
        ]).isRequired,
    };

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { title, children, classes, subtitle } = this.props;

        return (
			<section>
                <div className={classes.header}>
                    <Typography variant="headline" align="center">
                        {title}
                    </Typography>
                    {subtitle && 
                        <Typography variant="caption" color="textSecondary" align="center">
                            {subtitle}
                        </Typography>
                    }
                </div>
				<Paper className={classes.root} elevation={0}>
					<div className={classes.tableWrapper}>
						{children}
					</div>
				</Paper>
            </section>
        );
    }
}

export default withStyles(styles)(SortableTableWrapper);