import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class ContentLoader extends React.Component {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired, 
        children: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.node),
        ]).isRequired,
    };

    render() {
        const { isLoading, children } = this.props;

        if (isLoading) return <CircularProgress size={20} />
        else return children;
    }
}