export default [{
    id: '1',
    title: 'Frontend Libraries',
    color: '#ec9c16',
    icon: 'important_devices',
    packagesIds: ['angular/angular', 'facebook/react', 'vuejs/vue'],
}, {
    id: '2',
    title: 'Machine Learning',
    color: '#ff0469',
    icon: 'blur_on',
    packagesIds: ['tensorflow/tensorflow', 'pytorch/pytorch', 'theano/theano', 'keras-team/keras'],
}, {
    id: '3',
    title: 'Text Editors',
    color: '#006ce3',
    icon: 'format_size',
    packagesIds: ['atom/atom', 'microsoft/vscode', 'adobe/brackets', 'textmate/textmate'],
}, {
    id: '4',
    title: 'Blockchain',
    color: '#ff4c16',
    icon: 'link',
    packagesIds: ['eosio/eos', 'hyperledger/fabric', 'exonum/exonum', 'ethereum/go-ethereum'],
}, {
    id: '5',
    title: 'Containers',
    color: '#00cdff',
    icon: 'dns',
    packagesIds: ['kubernetes/kubernetes', 'docker/swarm', 'apache/mesos'],
}, {
    id: '6',
    title: 'Publishing Platforms',
    color: '#8fe527',
    icon: 'send',
    packagesIds: ['tryghost/ghost', 'hexojs/hexo', 'gatsbyjs/gatsby', 'jekyll/jekyll', 'gohugoio/hugo'],
}];