import React, { PureComponent } from 'react';
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis } from 'recharts';
import { getColorForString } from '../../../utils';
import style from '../style';

class PackageSummaryChart extends PureComponent {
    render() { 
        const { packageSummaryData } = this.props;
        const packageColor = getColorForString(packageSummaryData.id);
        
        return (
            <div style={{ display: 'inline-block' }}>
                <RadarChart outerRadius={80} width={300} height={300} data={packageSummaryData.data}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="topic" tick={style.polarTicks}/>
                    <PolarRadiusAxis domain={[0, 10]} tick={false} axisLine={false} />
                    <Radar  name={packageSummaryData.name} dataKey="score" stroke={packageColor} fill={packageColor} fillOpacity={0.6} />
                    <Legend wrapperStyle={style.legend} />
                </RadarChart>
            </div>
        );
    }
}

export default PackageSummaryChart;
