export default [{
  id: '1',
  title: 'Frontend Libraries',
  packagesIds: ['angular/angular', 'facebook/react', 'vuejs/vue'],
}, {
  id: '2',
  title: 'Store Management',
  packagesIds: ['reduxjs/redux', 'mobxjs/mobx'],
}, {
  id: '3',
  title: 'React Native Navigation',
  packagesIds: [
    'reacttraining/react-router', 
    'wix/react-native-navigation', 
    'react-navigation/react-navigation', 
  ],
}, {
  id: '4',
  title: 'HTTP Clients',
  packagesIds: [
    'axios/axios', 
    'matthew-andrews/isomorphic-fetch', 
    'request/request-promise', 
    'bitinn/node-fetch'
  ],
}, {
  id: '5',
  title: 'Date/Time Utilities',
  packagesIds: [
    'date-fns/date-fns',
    'moment/moment'
  ],
}, {
  id: '6',
  title: 'Charting Libraries',
  packagesIds: [
    'chartjs/chart.js',
    'apexcharts/apexcharts.js',
    'plotly/plotly.js'
  ],
}];