import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

const styles = theme => ({
	text: {
        marginTop: 20,
        marginBottom: 22,
        textAlign: 'center',
    }
});

class NoPackages extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    render() {
        const { classes } = this.props;

        return (
			<section className={classes.root}>
                <Typography align="center" variant="subheading" style={{ marginTop: 20 }}>
                    WELCOME TO YOUR NEW REPORT!
                </Typography>
                <Typography align="center" style={{ marginTop: 10 }}>
                    <Icon style={{ fontSize: 64 }}>
                        gesture
                    </Icon>
                </Typography>
                <Typography align="center" variant="subheading" style={{ marginTop: 20 }}>
                    START BY SEARCHING OR PASTING A GITHUB URL IN THE SEARCH BAR.
                </Typography>
            </section>
        );
    }
}

export default withStyles(styles)(NoPackages);