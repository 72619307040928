import React from 'react';
import { Table, TableBody } from '@material-ui/core';
import { SortableTable, SortableTableHead, SortableTableWrapper } from '../SortableTable';
import PackagesActivityTableRow from './PackagesActivityTableRow';
import { sortPackages } from '../../utils';

export default class PackagesActivityTable extends SortableTable {
	constructor(props, context) {
		super(props, context);

		this.state = {
			order: 'desc',
			orderBy: 'lastCommitDate',
			columns: [
        { id: 'name', numeric: false, disablePadding: true, label: 'Name', icon: '🆔' },
        { id: 'lastCommitDate', numeric: false, disablePadding: true, label: 'Last Commit', icon: '📆' },
        { id: 'commits', numeric: false, disablePadding: true, label: 'Commits', icon: '🚧' },
        { id: 'contributors', numeric: false, disablePadding: true, label: 'Contributors', icon: '👨‍👩‍👧‍👦' },
        { id: 'openIssues', numeric: false, disablePadding: true, label: 'Open Issues', icon: 'ℹ️' },
        { id: 'totalIssues', numeric: false, disablePadding: true, label: 'Total Issues', icon: 'ℹ️' },
        { id: 'openPulls', numeric: false, disablePadding: true, label: 'Open PR\'s', icon: '🛠️' },
        { id: 'totalPulls', numeric: false, disablePadding: true, label: 'Total PR\'s', icon: '🛠️' },
			],
			data: [],
		};
	}

  static getDerivedStateFromProps(nextProps, prevState) {
		const { orderBy, order } = prevState;
    const propsData = nextProps.packages
    .filter(currentPackage => (!!currentPackage.general.data))
    .map(currentPackage => {
          const { full_name, has_issues } = currentPackage.general.data;
          const { name } = currentPackage;
          
          // Set pulls
          const openPulls = currentPackage.pulls.isLoaded ? {
            value: currentPackage.pulls.open,
            isLoading: currentPackage.pulls.isLoading,
          } : {
            value: null,
            isLoading: currentPackage.pulls.isLoading,
          };

          const totalPulls = currentPackage.pulls.isLoaded ? {
            value: currentPackage.pulls.total,
            isLoading: currentPackage.pulls.isLoading,
          } : {
            value: null,
            isLoading: currentPackage.pulls.isLoading,
          };

          const openIssues = currentPackage.issues.isLoaded ? {
            value: has_issues ? currentPackage.issues.open : null,
            isLoading: currentPackage.issues.isLoading,
          } : {
            value: null,
            isLoading: currentPackage.issues.isLoading,
          };

          const totalIssues = currentPackage.issues.isLoaded ? {
            value: has_issues ? currentPackage.issues.total : null,
            isLoading: currentPackage.issues.isLoading,
          } : {
            value: null,
            isLoading: currentPackage.issues.isLoading,
          };

          // Set commits 
          const commits = currentPackage.commits.isLoaded ? {
            value: currentPackage.commits.total,
            isLoading: currentPackage.commits.isLoading,
          } : {
            value: null,
            isLoading: currentPackage.commits.isLoading,
          };

          // Set contributors 
          const contributors = currentPackage.contributors.isLoaded ? {
            value: currentPackage.contributors.total,
            isLoading: currentPackage.contributors.isLoading,
          } : {
            value: null,
            isLoading: currentPackage.contributors.isLoading,
          };

          // Set lastCommitDate 
          const lastCommitDate = currentPackage.commits.isLoaded ? {
            value: currentPackage.commits.lastCommitDate,
            isLoading: currentPackage.commits.isLoading,
          } : {
            value: null,
            isLoading: currentPackage.commits.isLoading,
          };

          return {
              id: full_name,
              name,
              lastCommitDate,
              commits,
              contributors,
              openPulls, 
              totalPulls, 
              openIssues,
              totalIssues, 
          };
    });

    const data = sortPackages(propsData, orderBy, order);

    return {
      ...prevState,
      data,
    };
  }

	render() {
		const { data, order, orderBy, columns } = this.state;
		if (data.length === 0) return null;

		return (
			<SortableTableWrapper title="Activity Information" subtitle="Maintainance and Vibrancy Metrics">
				<Table>
					<SortableTableHead
						order={order}
						orderBy={orderBy}
						onRequestSort={this.handleRequestSort}
						columns={columns}
					/>
					<TableBody>
						{data.map(currentPackage => <PackagesActivityTableRow key={currentPackage.id} package={currentPackage} />)}
					</TableBody>
				</Table>
			</SortableTableWrapper>
		);
	}
}

