import { List } from 'immutable';
import {
    REMOVE_PACKAGE_STARTED,
    REMOVE_PACKAGE_SUCCESS,
    REMOVE_PACKAGE_FAIL,
    ADD_PACKAGE_STARTED,
    ADD_PACKAGE_SUCCESS,
    ADD_PACKAGE_FAIL,
    GET_PACKAGE_PULLS_STARTED,
    GET_PACKAGE_PULLS_SUCCESS,
    GET_PACKAGE_PULLS_FAIL,
    GET_PACKAGE_COMMITS_STARTED,
    GET_PACKAGE_COMMITS_SUCCESS,
    GET_PACKAGE_COMMITS_FAIL,
    GET_PACKAGE_CONTRIBUTORS_STARTED,
    GET_PACKAGE_CONTRIBUTORS_SUCCESS,
    GET_PACKAGE_CONTRIBUTORS_FAIL,
    GET_PACKAGE_ACTIVITY_STARTED,
    GET_PACKAGE_ACTIVITY_SUCCESS,
    GET_PACKAGE_ACTIVITY_FAIL,
    GET_PACKAGES_DOWNLOADS_STARTED,
    GET_PACKAGES_DOWNLOADS_SUCCESS,
    GET_PACKAGES_DOWNLOADS_FAIL,
} from '../../types/packages';

import addPackage from './addPackage';
import removePackage from './removePackage';
import getPackagePulls from './getPackagePulls';
import getPackageCommits from './getPackageCommits';
import getPackageContributors from './getPackageContributors';
import getPackageActivity from './getPackageActivity';
import getPackagesDownloads from './getPackagesDownloads';

export default function(state = new List(), action) {
    switch(action.type) {
        case ADD_PACKAGE_STARTED:
        case ADD_PACKAGE_SUCCESS:
        case ADD_PACKAGE_FAIL:
            return addPackage(state, action);
        case REMOVE_PACKAGE_STARTED:
        case REMOVE_PACKAGE_SUCCESS:
        case REMOVE_PACKAGE_FAIL:
            return removePackage(state, action);
        case GET_PACKAGE_PULLS_STARTED:
        case GET_PACKAGE_PULLS_SUCCESS:
        case GET_PACKAGE_PULLS_FAIL:
            return getPackagePulls(state, action);
        case GET_PACKAGE_COMMITS_STARTED:
        case GET_PACKAGE_COMMITS_SUCCESS:
        case GET_PACKAGE_COMMITS_FAIL:
            return getPackageCommits(state, action);
        case GET_PACKAGE_CONTRIBUTORS_STARTED:
        case GET_PACKAGE_CONTRIBUTORS_SUCCESS:
        case GET_PACKAGE_CONTRIBUTORS_FAIL:
            return getPackageContributors(state, action);
        case GET_PACKAGE_ACTIVITY_STARTED:
        case GET_PACKAGE_ACTIVITY_SUCCESS:
        case GET_PACKAGE_ACTIVITY_FAIL:
            return getPackageActivity(state, action);
        case GET_PACKAGES_DOWNLOADS_STARTED:
        case GET_PACKAGES_DOWNLOADS_SUCCESS:
        case GET_PACKAGES_DOWNLOADS_FAIL:
            return getPackagesDownloads(state, action);
        default: return state;
    }
}