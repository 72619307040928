import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

class NotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount(){
        document.title = 'GitCompare - Not Found';
    }

    render() {
        return (
            <section>
                <Typography align="center" variant="headline" style={{ marginTop: 20 }}>
                    Not Found
                </Typography>
                <Typography align="center">
                    <Icon style={{ fontSize: 64 }}>
                        face
                    </Icon>
                </Typography>
            </section>
        );
    }
}

export default NotFound;
