import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { configureStore, history } from './store/configure';
import { Home, Report, Reports, NotFound, FAQ } from './containers';
import { Header } from './components/Header';
import Footer from './components/Footer';

const store = configureStore();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <React.Fragment>
          <CssBaseline />
          <ConnectedRouter history={history}>
            <main>
              <Header addPackage={() => {}} />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/report" component={Report} />
                <Route exact path="/reports" component={Reports} />
                <Route exact path="/faq" component={FAQ} />
                <Route component={NotFound} />
              </Switch>
              <Footer />
            </main>
          </ConnectedRouter>
        </React.Fragment>
      </Provider>
    );
  }
}

export default App;
