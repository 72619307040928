import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CombinationCard from './CombinationCard';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
	root: {
        flexGrow: 1,
        margin: theme.spacing.unit,
	},
	header: {
		margin: theme.spacing.unit * 4,
	},
});

class CombinationsGrid extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        classes: PropTypes.object.isRequired,
        combinations: PropTypes.array.isRequired,
    };

    render() {
        const { classes, combinations, title } = this.props;

        return (
            <section className={classes.root}>
                {title && <Typography className={classes.header} variant="headline" align="center">
                    {title}
                </Typography>}
                <Grid container spacing={16} justify="space-around">
                    {combinations.map(combination =>
                        <CombinationCard key={combination.id} combination={combination} />
                    )}
                </Grid>
            </section>
        );
    }
}

export default withStyles(styles)(CombinationsGrid);