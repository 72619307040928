import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableCell, TableRow, Button } from '@material-ui/core';
import ContentLoader from '../ContentLoader';

const styles = theme => ({
	link: {
		textDecoration: 'none',
		fontWeight: '500',
    },
    badge: {
        display: 'inline-block',
        verticalAlign: 'middle',
        lineHeight: 0,
        minHeight: 32,
        padding: theme.spacing.unit,
    },
    cell: {
        padding: theme.spacing.unit * 2,
    },
    centeredCell: {
        textAlign: 'center',
        padding: theme.spacing.unit,
    },
});

class PackagesBadgesTableRow extends React.Component {
    state = {
        showOtherShields: false,
    }
    
	static propTypes = {
		package: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
    }
    
    renderShield = (shield, idx) => {
        const {  classes, package: { id } } = this.props;

        return <a key={`${id}${idx}`} title={shield.title} href={shield.url} className={classes.badge} target="_blank" rel="noopener noreferrer">
            <img src={shield.imageURL} alt={shield.title}/>
        </a>;
    }

    toggleOtherShields = () => {
        const { showOtherShields } = this.state;
        this.setState({ showOtherShields: !showOtherShields });
    }

	render() {
        const { showOtherShields } = this.state;
        const {  classes, package: { id, name, shields } } = this.props;
        const mainShields = shields.values.slice(0,5);
        const otherShields =  shields.values.slice(5);

		return (
			<TableRow
				hover
				tabIndex={-1}
				key={id}
			>
                <TableCell className={classes.cell}>
                    <a 
                      rel="noopener noreferrer"
                      className={classes.link} 
                      target="_blank" 
                      href={`https://github.com/${id}`}>
                      {name.toUpperCase()}
                    </a>
                </TableCell>
                <TableCell className={classes.cell}>
                    <ContentLoader isLoading={shields.isLoading}>
                        {mainShields.map(this.renderShield)}
                        {showOtherShields && otherShields.map(this.renderShield)}
                    </ContentLoader>
                    {otherShields.length > 0 && 
                        <Button size="small" variant="text" color="primary" onClick={this.toggleOtherShields} >
                            {showOtherShields ? 'Hide Extra Badges' : `Show ${otherShields.length} More Badges`} 
                        </Button>
                    }
                </TableCell>
			</TableRow>
		);
	}
}

export default withStyles(styles)(PackagesBadgesTableRow);