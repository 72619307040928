import React, { Component } from 'react';
import AutoComplete from './AutoComplete';
import github from '../../../api/github';
import { getPackageId } from '../../../utils';

const style = {
    input: {
        height: '36px',
        display: 'inline-block',
        borderRadius: '1000em',
        width: '100%',
        outline: 'none',
        padding: '0 12px',
        fontSize: '16px',
        border: '1px solid #CCC',
        textTransform: 'uppercase',
    },
    form: {
        width: '100%',
    }
}

class SearchForm extends Component {
    state = { searchQuery: '' };

    createInputRef = (autosuggest) => {
        if (autosuggest !== null) {
            this.inputRef = autosuggest.input;
        }
    }

    searchRepositories = async (query) => {
        try {
            const apiCall = await github.searchRepositories(query);

            if (apiCall.status === 200) {
                const data = await apiCall.json();
                return data;
            } else {
                return [];
            }
        } catch(err) {
            console.error(err);
            return [];
        }
    }

    addPackage = (packageId) => {
        const { addPackage } = this.props;
        addPackage(packageId);
        this.setState({ searchQuery: ''});
    }

    handleSubmit = (e) => {
        // Stop event
        e.preventDefault();
        e.stopPropagation();

        // // Get information
        const { value } = this.inputRef;
        const packageId = getPackageId(value);
        
        if(packageId) this.addPackage(packageId);
    }

    handleSelection = (e, { suggestion }) => {
        // Stop event
        e.preventDefault();
        e.stopPropagation();
        
        // Add a package
        this.addPackage(suggestion.full_name);
    }

    setSearchQuery = (event, { newValue: searchQuery }) => this.setState( { searchQuery } );

    render() {
        const { searchQuery } = this.state;

        return (
            <form style={style.form} onSubmit={this.handleSubmit}>
                <AutoComplete setSearchQuery={this.setSearchQuery} searchQuery={searchQuery} searchRepositories={this.searchRepositories} createInputRef={this.createInputRef} handleSelection={this.handleSelection}/>
            </form>
        );
    }
}

export default SearchForm;
