import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom'

const styles = theme => ({
	text: {
        marginTop: 20,
        marginBottom: 22,
        textAlign: 'center',
    }
});

class Footer extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    render() {
        const { classes } = this.props;

        return (
			<section className={classes.root}>
                <Typography className={classes.text} variant="caption" align="center">
                    © 2018 GitCompare
                    <span> • </span>
                    <a href="mailto:gitcompare@gmail.com">Contact</a>
                    <span> • </span>
                    <Link to="/faq">FAQ</Link>
                </Typography>
            </section>
        );
    }
}

export default withStyles(styles)(Footer);