import React from 'react';
import { Table, TableBody } from '@material-ui/core';
import { SortableTable, SortableTableHead, SortableTableWrapper } from '../SortableTable';
import PackagesGeneralTableRow from './PackagesGeneralTableRow';
import { sortPackages } from '../../utils';

export default class PackagesGeneralTable extends SortableTable {
	constructor(props, context) {
		super(props, context);

		this.state = {
			order: 'desc',
			orderBy: 'stars',
			columns: [
				{ id: 'name', numeric: false, disablePadding: true, label: 'Name', icon: '🆔' },
				{ id: 'created', numeric: false, disablePadding: true, label: 'Created', icon: '📆' },
				{ id: 'license', numeric: false, disablePadding: true, label: 'License', icon: '📝' },
				{ id: 'stars', numeric: false, disablePadding: true, label: 'Stars', icon: '⭐' },
				{ id: 'watchers', numeric: false, disablePadding: true, label: 'Watchers', icon: '👀' },
				{ id: 'forks', numeric: false, disablePadding: true, label: 'Forks', icon: '🔀' },
			],
			data: [],
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const { orderBy, order } = prevState;
		const propsData = nextProps.packages
		.filter(currentPackage => !!currentPackage.general.data)
		.map(currentPackage => {
			const { full_name, created_at, stargazers_count, forks, subscribers_count, license } = currentPackage.general.data;
			const { name } = currentPackage;
			return {
				id: full_name,
				name,
				created: new Date(created_at),
				stars: stargazers_count,
				forks,
				watchers: subscribers_count,
				license: license ? license.name : '',
			};
		});

		const data = sortPackages(propsData, orderBy, order);

		return {
			...prevState,
			data,
		};
	}

	render() {
		const { data, order, orderBy, columns } = this.state;
		if (data.length === 0) return null;

		return (
			<SortableTableWrapper title="General Information" subtitle="General and Community Metrics">
				<Table>
					<SortableTableHead
						order={order}
						orderBy={orderBy}
						onRequestSort={this.handleRequestSort}
						columns={columns}
					/>
					<TableBody>
						{data.map(currentPackage => <PackagesGeneralTableRow key={currentPackage.id} package={currentPackage} />)}
					</TableBody>
				</Table>
			</SortableTableWrapper>
		);
	}
}
