import React from 'react';
import Paper from '@material-ui/core/Paper';

const style = {
    border: '1px solid #CCC',
    borderTop: 0,
    borderRadius: '0 0 3px 3px',
}

export default function renderSuggestions(props) {
    const { containerProps, children } = props;
    if (!children) return null;

    return (
      <Paper style={style} elevation={0} {...containerProps} square>
          {children}
      </Paper>
    );
}
  