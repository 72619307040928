import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';

const styles = theme => ({
    avatar: {
        height: 24, 
        width: 24,
        margin: theme.spacing.unit / 2,
        backgroundColor: '#fff',
    },
    root: {
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap',
        padding: theme.spacing.unit / 2,
        borderBottom: '1px solid #ccc',
    },
    chip: {
        textTransform: 'uppercase',
        margin: theme.spacing.unit / 2,
        '&:hover': {
            backgroundColor: '#d0d0d0',
        },
    },
    link: {
        textDecoration: 'none',
        fontWeight: '500',
    },
    button: {
        marginRight: theme.spacing.unit / 2,
    },
    icon: {
        marginRight: theme.spacing.unit,
    }, 
    chipsContainer: {
        flex: 1,
    }
});

const errorAvatar = (
    <Tooltip title="Package could not be loaded" placement="top">
        <Icon color="error">
            error
        </Icon>
    </Tooltip>
);

class PackagesChips extends PureComponent {
    renderAvatar = (currentPackage) => {
        if (currentPackage.general.errors.length > 0) return errorAvatar;
        return undefined;
    }

    render() {
        const { removePackage, packages, classes } = this.props;
        if (packages.length === 0) return null;

        return (
            <Paper className={classes.root} elevation={0}>
                <div className={classes.chipsContainer}>
                    { packages.map(currentPackage => (
                        <Chip
                            key={currentPackage.id}
                            label={<a className={classes.link} target="_blank" rel="noopener noreferrer" href={`https://github.com/${currentPackage.id}`}>{currentPackage.id}</a>}
                            onDelete={() => removePackage(currentPackage.id)}
                            avatar={this.renderAvatar(currentPackage)}
                            deleteIcon={currentPackage.general.isLoading ? <CircularProgress size={24} /> : undefined}
                            className={classes.chip}
                        />
                    ))} 
                </div>
                <Button className={classes.button} to={{ pathname: 'report'}} size="small" color="primary" component={Link} variant="text">
                    <Icon className={classes.icon}>add_circle</Icon>New Report
                </Button>
            </Paper>
        );
    }
}

export default withStyles(styles)(PackagesChips);