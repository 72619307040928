import React, { Component } from 'react';
import EmailSubscription from '../components/EmailSubscription';
import Hero from '../components/Hero';
import GridLink from '../components/GridLink';
import { CombinationsGrid } from '../components/CombinationsGrid';
import TopCombinations from '../data/TopCombinations';
import TrendingProjects from '../data/TrendingProjects';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount(){
        document.title = 'GitCompare';
    }

    render() {
        return (
            <section>
                <Hero />
                <CombinationsGrid title="Top Reports" combinations={TopCombinations} />
                <GridLink to={{ pathname: 'reports' }} />
                <EmailSubscription />
                <CombinationsGrid title="Top Repositories" combinations={TrendingProjects} />
            </section>
        );
    }
}

export default Home;
