import React, { Component } from 'react';
import { CombinationsGrid } from '../components/CombinationsGrid';
import JSCombinations from '../data/JSCombinations';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount(){
        document.title = 'GitCompare - Reports';
    }

    render() {
        return (
            <section>
                <CombinationsGrid title="JavaScript Reports" combinations={JSCombinations} />
            </section>
        );
    }
}

export default Home;
