import getRepository from './getRepository';
import getRepositoryPulls from './getRepositoryPulls';
import getRepositoryCommits from './getRepositoryCommits';
import getRepositoryContributors from './getRepositoryContributors';
import getRepositoryActivity from './getRepositoryActivity';
import searchRepositories from './searchRepositories';

export default {
    getRepository,
    getRepositoryPulls,
    getRepositoryCommits,
    getRepositoryContributors,
    getRepositoryActivity,
    searchRepositories,
};