import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
	root: {
        padding: theme.spacing.unit,
        marginTop: theme.spacing.unit / 2,
    },
    question: {
        marginTop: theme.spacing.unit * 2,
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        fontSize: 20,
        marginRight: theme.spacing.unit / 2,
    },
    answer: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    list: {
        paddingTop: 10,
        paddingLeft: 40,
    }
});

class FAQ extends Component {
    componentDidMount(){
        document.title = 'GitCompare - FAQ';
        document.head.querySelector("[name=description]").content = 'Questions that people usally have.' ; 
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid container className={classes.root} justify="space-around">
                <Grid item md={6} sm={12} xs={12}>
                    <Typography align="center">
                        <Icon style={{ fontSize: 64 }}>
                            question_answer
                        </Icon>
                    </Typography>
                    <Typography variant="body1" color="textSecondary" className={classes.question}>
                        <Icon className={classes.icon}>help</Icon>
                        Why did you build GitCompare?
                    </Typography>
                    <Typography variant="body1" className={classes.answer}>
                        <Icon className={classes.icon}>info</Icon>
                        We've been working as software developers for almost 15 years and during this period we've had the pleasure to witness many paradigm shifts in software development, including the rise in adoption and development of open source projects. 
                        <br /><br />
                        This movement lead to a burst of creativity in the community and at this point it seems obvious that there's a need for better tools to assess, discuss, and evaluate alternatives and technical choices.
                        <br /><br />
                        This is why we built GitCompare! We hope it is a first in a set of tools that will make life easier for engineers and technical leaders.
                    </Typography>
                    <Typography variant="body1" color="textSecondary" className={classes.question}>
                        <Icon className={classes.icon}>help</Icon>
                        How should I use GitCompare?
                    </Typography>
                    <Typography variant="body1" className={classes.answer}>
                        <Icon className={classes.icon}>info</Icon>
                        As we use GitCompare ourselves, we've provided you with several example reports.
                        <br /><br />
                        However, the real fun begins when you create your own reports!
                        <br />
                        Use the searchbar on the top of the page and paste a repository url or search for a repository, once you're in the report page, add as many repositories you'd like to research and compare them against each other.
                        <br /><br />
                        * You can share the reports you create by copying the URL and sending it to your colleagues.
                    </Typography>
                    <Typography variant="body1" color="textSecondary" className={classes.question}>
                        <Icon className={classes.icon}>help</Icon>
                        How do you collect the repository information?
                    </Typography>
                    <Typography variant="body1" className={classes.answer}>
                        <Icon className={classes.icon}>info</Icon>
                        We currently rely on Github's API, but efforts are in place to integrate other service providers. 
                        <br /><br />
                        As we try to stay compliant with Github's terms of service, our data is being cached for a short period of time. 
                        <br />
                        First on our servers and second on your machine, this allows us to provide quick and consistent service. 
                        <br />
                        It also means that some slight discrepancies can appear in regards to several data nodes, these are kept to an insignificant minimum by our cache purging mechanisms.
                    </Typography>
                    <Typography variant="body1" color="textSecondary" className={classes.question}>
                        <Icon className={classes.icon}>help</Icon>
                        How do you collect downloads information?
                    </Typography>
                    <Typography variant="body1" className={classes.answer}>
                        <Icon className={classes.icon}>info</Icon>
                        Due to our current reliance on NPM as a data provider, downloads information is only available with JavaScript libraries.
                        <br /><br />
                        This will be upgraded soon as we look into other data providers.
                    </Typography>
                    <Typography variant="body1" color="textSecondary" className={classes.question}>
                        <Icon className={classes.icon}>help</Icon>
                        I found a discrepancy in the data!
                    </Typography>
                    <Typography variant="body1" className={classes.answer}>
                        <Icon className={classes.icon}>info</Icon>
                        Cool! If it's a small difference, know that we deliberately ignore micro changes in favor of performance using caching. 
                        <br />
                        If it's a big difference then it would be great if you can let us know about it.
                    </Typography>
                    <Typography variant="body1" color="textSecondary" className={classes.question}>
                        <Icon className={classes.icon}>help</Icon>
                        Are you affiliated with another company?
                    </Typography>
                    <Typography variant="body1" className={classes.answer}>
                        <Icon className={classes.icon}>info</Icon>
                        GitCompare is an independent project. We are not affilated with any other company.
                    </Typography>
                    <Typography variant="body1" color="textSecondary" className={classes.question}>
                        <Icon className={classes.icon}>help</Icon>
                        How do you calculate the summary scores?
                    </Typography>
                    <Typography variant="body1" className={classes.answer}>
                        <Icon className={classes.icon}>info</Icon>
                        Good Question! Generally all calculations are done relatively, a score is being given to present the relative correlation between packages, that's why summary is not being shown for single packages(Yet!).
                        To be precise, each calculation is influenced by repository data points as follows: 
                    </Typography>
                    <Typography variant="body1" className={classes.list}>
                        <strong>Activity</strong> - Represents a repository general development activity, influenced by the amounts of commits, forks, and time from last commit.
                        <br />
                        <strong>Community</strong> - Represents a repository community strength, influenced by the amounts of stars, watchers, forks, and contributors.
                        <br />
                        <strong>Engagement</strong> - Represents how people engage with a repository, influenced by the amounts of stars, watchers, forks, contributors, issues, and PRs.
                        <br />
                        <strong>Maintenance</strong> - Represents well maintained a repository is, influenced by the amounts of contributors, issues, and PRs(Open and closed).
                        <br />
                        <strong>Adoption</strong> - In case we have "downloads" information this represent a relative adoption score.
                    </Typography>
                    <Typography variant="body1" color="textSecondary" className={classes.question}>
                        <Icon className={classes.icon}>help</Icon>
                        Can I use the information found on GitCompare?
                    </Typography>
                    <Typography variant="body1" className={classes.answer}>
                        <Icon className={classes.icon}>info</Icon>
                        We encourage you to share, comment, analyze, and criticize any of the information found on our application, however as a free data source we are not liable for any decisions made using this data.
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(FAQ);
