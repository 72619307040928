import { getPackagesIdsFromQSParams  } from '../../utils';
import { reportPackages } from '../../api/analytics';

export default function reportPackagesMiddleware(store){
    return next => action => {
        if (action.type === '@@router/LOCATION_CHANGE') {
            const paramsPackages = getPackagesIdsFromQSParams({ location: action.payload });
            if (paramsPackages.length > 0) reportPackages(paramsPackages);
        }
        
        return next(action);
    }
}