import React from 'react';
import Typography from '@material-ui/core/Typography';

export default class Twitter extends React.Component {
    componentDidMount = () => {
        if (window.twttr) window.twttr.widgets.load()
    }

    render() {
        return <a href="https://twitter.com/GitCompare?ref_src=twsrc%5Etfw" className="twitter-follow-button" data-show-count="true">
            <Typography variant="body1">
                FOLLOW @GITCOMPARE
            </Typography>
        </a>;
    }
}