import getRoundedScore from './getRoundedScore';

export default function getMaintenanceScore(currentPackage, packages) {
    let packagesTotalIssues = 0;
    let packagesTotalPulls = 0;
    let packagesTotalContributors = 0;

    packages.filter(nextPackage => {
        return (nextPackage.general.isLoaded && nextPackage.issues.isLoaded && nextPackage.contributors.isLoaded && currentPackage.pulls.isLoaded);
    }).forEach(nextPackage => {
        packagesTotalContributors += nextPackage.contributors.total;
        packagesTotalIssues += nextPackage.issues.total;
        packagesTotalPulls += nextPackage.pulls.total;
    });
    
    const packageRelativeContributors = currentPackage.contributors.total / packagesTotalContributors;
    const packageRelativeIssues = currentPackage.issues.total / packagesTotalIssues;
    const packageRelativePulls = currentPackage.pulls.total / packagesTotalPulls;
    const averageScore = (packageRelativeIssues * 0.3 + 
                          packageRelativePulls * 0.3 + 
                          packageRelativeContributors * 0.4);
    const roundedScore = getRoundedScore(100 * averageScore, -1);
    
    return roundedScore;
}