export default {
    scatterLegend: {
        fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: 14,
        textTransform: 'uppercase',
        bottom: 0,
    },
    legend: {
        fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: 14,
        textTransform: 'uppercase',
        bottom: 10,
        padding: 10,
    },
    labels: {
        fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: 12,
        textTransform: 'uppercase',
    },
    polarTicks: {
        fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: 10,
        textTransform: 'uppercase',
    },
    tooltip: {
        fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: 12,
        textTransform: 'uppercase',
        zIndex: 100,
    },
    tooltipInner: {
        textAlign: 'center',
        backgroundColor: '#fff', 
        border: '1px solid #DDD', 
        margin: 0, 
        paddingRight: 8,
        paddingLeft: 8,
    },
    titles: {
        fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
        fontSize: 14,
        textTransform: 'uppercase',
        fontWeight: 700,
    },
};