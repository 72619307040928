import getRoundedScore from './getRoundedScore';

export default function getAdoptionScore(currentPackage, packages) {
    const totalPackagesDownloads = packages.reduce((accumulator, nextPackage) => {
        let packageTotalDownloads = 0;
        if (nextPackage.downloads.isLoaded && nextPackage.downloads.data) {
            for (let i = nextPackage.downloads.data.length - 1; i > nextPackage.downloads.data.length - 31 && i >= 0; i--) {
                packageTotalDownloads += nextPackage.downloads.data[i].downloads;
            }
        }

        return accumulator + packageTotalDownloads;
    }, 0);

    let packageTotalDownloads = 0;
    if (currentPackage.downloads.isLoaded && currentPackage.downloads.data) {
        for (let i = currentPackage.downloads.data.length - 1; i > currentPackage.downloads.data.length - 31 && i >= 0; i--) {
            packageTotalDownloads += currentPackage.downloads.data[i].downloads;
        }
    }

    const percentageOfDownloadsCompareToOtherPackages = 100 * packageTotalDownloads / totalPackagesDownloads;
    const roundedScore = getRoundedScore(percentageOfDownloadsCompareToOtherPackages, -1);

    return roundedScore;
}