import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import { getColorForString } from '../../utils';

const styles = theme => ({
	root: {
    },
    card: {
        backgground: '#fff',
        border: '1px solid #DDD',
    },
    headerContainer: {
        padding: theme.spacing.unit,
    },
    header: {
        display: 'flex',  
        alignItems: 'center',
    },
    title: {
        flex: 1,
        marginLeft: -40,
    },
    contentContainer: {
        minHeight: 140,
        display: 'flex',  
        justifyContent: 'center',
        alignItems: 'center',
    },
    list: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
        textAlign: 'center',
    },
    description: {
        textAlign: 'center',
    },
    avatar: {
        color: '#fff',
        backgroundColor: '#333',
    }
});

class CombinationCard extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        combination: PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            icon: PropTypes.string,
            packagesIds: PropTypes.arrayOf(PropTypes.string).isRequired,
        }),
    };

    renderCardHeader = () => {
        const { classes, combination } = this.props;

        return (
            <section className={classes.header}>
                {(combination.icon || !combination.avatar) && 
                    <Avatar style={{ background: combination.color || getColorForString(combination.title) }}>
                        {
                            combination.icon ?
                            <Icon>{combination.icon}</Icon> :
                            <Icon>reorder</Icon>
                        }
                    </Avatar>
                }
                {combination.avatar && <Avatar src={combination.avatar}></Avatar>}
                <Typography className={classes.title} align="center" variant="subheading" color="default">{combination.title}</Typography>
            </section>
        );
    }

    render() {
        const { classes, combination } = this.props;

        return (
            <Grid item className={classes.root} xs={12} sm={6} md={4}>
                <Card elevation={0} className={classes.card}>
                    <CardHeader className={classes.headerContainer} subheader={this.renderCardHeader()} />
                    <CardContent className={classes.contentContainer}>
                        {!combination.description && <ul className={classes.list}>
                            {combination.packagesIds.map(packageId => 
                                <li key={`${combination.id}-${packageId}`}>
                                    <Typography variant="caption">{packageId.toUpperCase()}</Typography>
                                </li>
                            )}
                        </ul>}
                        {combination.description && <Typography className={classes.description} variant="body1" color="textSecondary">{combination.description}</Typography>}
                    </CardContent>
                    <CardActions>
                        <Button to={{ pathname: 'report', search: `?${combination.packagesIds.map(packageId => `packages=${packageId}`).join('&')}` }} component={Link} fullWidth variant="text" color="primary">
                            View Details
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        );
    }
}

export default withStyles(styles)(CombinationCard);