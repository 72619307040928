import React from 'react';
import createBrowserHistory from 'history/createBrowserHistory';
import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import { createDevTools } from 'redux-devtools';
import thunk from 'redux-thunk';
import LogMonitor from 'redux-devtools-log-monitor';
import DockMonitor from 'redux-devtools-dock-monitor';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './reducers';
import reportPackagesMiddleware from './middleware/reportPackages';

// Create a history of your choosing (we're using a browser history in this case)
export const history = createBrowserHistory();

const DevToolsComponent = (
    <DockMonitor toggleVisibilityKey="ctrl-h" changePositionKey="ctrl-w">
        <LogMonitor />
    </DockMonitor>
);

const getMiddleware = (history) => {
    if (process.env.NODE_ENV === 'production') {
        return applyMiddleware(routerMiddleware(history), thunk, reportPackagesMiddleware);
    } else {
        const DevTools = createDevTools(DevToolsComponent);

        return compose(
          applyMiddleware(routerMiddleware(history), thunk, createLogger(), reportPackagesMiddleware),
          DevTools.instrument(),
        );
    }
}

export const configureStore = (routerMiddleware) => {
    const store = createStore(
        createRootReducer(history),
        getMiddleware(history),
    );

    // Enable Webpack hot module replacement for reducers
    if (module.hot) {
        module.hot.accept('./reducers', () => store.replaceReducer(createRootReducer(history)));
    }

    return store;
}