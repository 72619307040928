import React, { PureComponent } from 'react';
import moment from 'moment';
import { LineChart, Line, XAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import InformationWrapper from '../InformationWrapper';
import { getColorForString } from '../../utils';
import style from './style';

function extractDownloadsData(packages) {
    let isLoading = false;
    const weekMap = {};
    let beginningOfWeek, endOfWeek, weeksData;

    // Check if all packages data was downloaded
    const allPackagesHasDownloads = packages.every(currentPackage => !!currentPackage.downloads.data);

    if (allPackagesHasDownloads) {
        packages.forEach(currentPackage => {
            if (currentPackage.downloads.data) {
                currentPackage.downloads.data.forEach(dayData => {
                    const currentDate = moment(dayData.day, 'YYYY-MM-DD');
                    
                    if (!beginningOfWeek || !endOfWeek || !currentDate.isBetween(beginningOfWeek, endOfWeek)) {
                        beginningOfWeek = moment(dayData.day, 'YYYY-MM-DD');
                        endOfWeek = moment(dayData.day, 'YYYY-MM-DD').add(7, 'days');
                    }
                    
                    if (weekMap[beginningOfWeek.unix()]) {
                        if (weekMap[beginningOfWeek.unix()][currentPackage.id]) {
                            weekMap[beginningOfWeek.unix()][currentPackage.id] += dayData.downloads; 
                        } else {
                            weekMap[beginningOfWeek.unix()][currentPackage.id] = dayData.downloads;
                        }
                    } else {
                        weekMap[beginningOfWeek.unix()] = {
                            [currentPackage.id]: dayData.downloads,
                        };
                    }
                });  
            } else if (currentPackage.downloads.isLoading) {
                isLoading = true;
            }
        });

        const weekMapAsArray = Object.entries(weekMap);
        weeksData = weekMapAsArray.slice(0, weekMapAsArray.length - 1).map(weekData => {
            const weekStart = parseInt(weekData[0], 10) * 1000;
            const weekStartLabel = moment(weekStart).format('MMM Do');
            const weekEndLabel = moment(weekStart).add(7, 'days').format('MMM Do');
            const weekStats = weekData[1];
            const name = `${weekStartLabel} - ${weekEndLabel}`;

            return { name, weekStart, ...weekStats };
        });
    } else {
        weeksData = [];
    }

    return { weeksData, isLoading };
}

class PackagesDownloadsChart extends PureComponent {
    renderTooltip = (props) => {
        const { active, payload, label } = props;
    
    
        if (active && payload && payload.length) {
            return (
                <div style={style.tooltipInner}>
                    <h4>Downloads</h4>
                    <p>{label}</p>
                    {payload
                     .sort((previousPackageData, currentPackageData) => previousPackageData.value < currentPackageData.value)
                     .map((packageData, idx) => (
                        <p key={idx} style={{color: packageData.color}}>{packageData.name}: {packageData.value}</p>
                    ))}
                </div>
            );
        }
    
        return null;
    }

    render() {
        const { packages } = this.props;
        if (packages.length === 0) return null;

        const downloadsData = extractDownloadsData(packages);
        if (downloadsData.weeksData.length === 0) return null;

        return (
            <InformationWrapper title="Annual Downloads" subtitle="Weekly Downloads Volume"> 
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart 
                        data={downloadsData.weeksData} 
                        margin={{ top: 10, bottom: 22, right: 10, left: 10 }}
                    >
                        <XAxis hide dataKey="name" labelStyle={style.titles}/>
                        <CartesianGrid strokeDasharray="3 3" margin={{ bottom: 10 }}/>
                        <Tooltip wrapperStyle={style.labels} content={this.renderTooltip} />
                        <Legend wrapperStyle={style.legend}/>
                        {packages.filter(currentPackage => !!currentPackage.downloads.data).map((currentPackage, idx) => (
                            <Line name={currentPackage.name} key={currentPackage.id} type="monotone" dataKey={currentPackage.id} stroke={getColorForString(currentPackage.id)} />
                        ))}
                    </LineChart>
                </ResponsiveContainer>
            </InformationWrapper>
        );
    }
}

export default PackagesDownloadsChart;
