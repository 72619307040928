import { fromJS } from 'immutable';
import { ADD_PACKAGE_FAIL, ADD_PACKAGE_STARTED, ADD_PACKAGE_SUCCESS } from '../../types/packages';

export default function addPackage(state, action) {
    let currentPackageIndex;

    switch (action.type) {
        case ADD_PACKAGE_STARTED:
            currentPackageIndex = state.findIndex(lookupPackage => lookupPackage.get('id') === action.packageId);

            if (currentPackageIndex < 0) {
                return state.push(fromJS({
                    id: action.packageId,
                    created: new Date().getTime(),
                    general: {
                        isLoading: true,
                        isLoaded: false,
                        data: null,
                        errors: [],
                    },
                    activity: {
                        isLoading: true,
                        isLoaded: false,
                        data: null,
                        errors: [],
                    },
                    pulls: {
                        isLoading: true,
                        isLoaded: false,
                        total: 0,
                        errors: [],
                    },
                    commits: {
                        isLoading: true,
                        isLoaded: false,
                        total: 0,
                        lastCommitDate: null,
                        errors: [],
                    },
                    contributors: {
                        isLoading: true,
                        isLoaded: false,
                        total: 0,
                        errors: [],
                    },
                    meta: {
                        isLoading: true,
                        isLoaded: false,
                        data: null,
                        errors: [],
                    },
                    downloads: {
                        isLoading: true,
                        isLoaded: false,
                        data: null,
                        errors: [],
                    },
                }));
            } 

            return state;
        case ADD_PACKAGE_SUCCESS:
            currentPackageIndex = state.findIndex(lookupPackage => lookupPackage.get('id') === action.packageId);

            if (currentPackageIndex >= 0) {
                if (action.cachedData) {
                    return state.set(currentPackageIndex, fromJS(action.cachedData));
                } else {
                    return state.setIn([currentPackageIndex, 'general', 'isLoading'], false)
                                .setIn([currentPackageIndex, 'name'], action.data.name)
                                .setIn([currentPackageIndex, 'general', 'isLoaded'], true)
                                .setIn([currentPackageIndex, 'general', 'data'], action.data)
                                .setIn([currentPackageIndex, 'general', 'errors'], []);
                }
            }

            return state;
        case ADD_PACKAGE_FAIL:
            currentPackageIndex = state.findIndex(lookupPackage => lookupPackage.get('id') === action.packageId);
            
            if (currentPackageIndex >= 0) {
                return state.setIn([currentPackageIndex, 'general', 'isLoading'], false)
                            .setIn([currentPackageIndex, 'general', 'errors'], action.errors);
            }

            return state;
        default:
            return state;
    }
}