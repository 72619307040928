import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
	root: {
        position: 'relative',
		borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        textAlign: 'center',
	},
	header: {
		margin: theme.spacing.unit * 4,
	},
});

class InformationWrapper extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.oneOfType([
            PropTypes.node, 
            PropTypes.string
        ]),
        classes: PropTypes.object.isRequired,
        children: PropTypes.oneOfType([
            PropTypes.node,
            PropTypes.arrayOf(PropTypes.node),
        ]).isRequired,
    };

    render() {
        const { title, subtitle, children, classes } = this.props;

        return (
			<section>
                <div className={classes.header}>
                    <Typography variant="headline" align="center">
                        {title}
                    </Typography>
                    {subtitle && 
                        <Typography variant="caption" color="textSecondary" align="center">
                            {subtitle}
                        </Typography>
                    }
                </div>
				<Paper className={classes.root} elevation={0}>
                    {children}
				</Paper>
            </section>
        );
    }
}

export default withStyles(styles)(InformationWrapper);