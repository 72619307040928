import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';

const styles = theme => ({
    cell: {
        padding: theme.spacing.unit * 2,
    },
});

class SortableTableHead extends React.Component {
    static propTypes = {
        columns: PropTypes.array.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.string.isRequired,
        orderBy: PropTypes.string.isRequired,
    };

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { classes, columns, order, orderBy } = this.props;

        return (
            <TableHead>
                <TableRow>
                    {columns.map(column => (
                            <TableCell
                                key={column.id}
                                numeric={column.numeric}
                                className={classes.cell}
                                sortDirection={orderBy === column.id ? order : false}
                            >
                                <TableSortLabel
                                    active={orderBy === column.id}
                                    direction={order}
                                    onClick={this.createSortHandler(column.id)}
                                >
                                    <span>
                                        {column.label}
                                    </span>
                                </TableSortLabel>
                            </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
}

export default withStyles(styles)(SortableTableHead);