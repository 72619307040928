import React, { PureComponent } from 'react';
import moment from 'moment';
import InformationWrapper from '../InformationWrapper';
import { ResponsiveContainer, ScatterChart, Scatter, YAxis, ZAxis, Tooltip, Legend } from 'recharts';
import { getColorForString } from '../../utils';
import style from './style';

function extractActivityData(packages) {
    let max = 0;
    let isLoading = false;

    const packagesActivityData = packages.filter(currentPackage => {
        if (currentPackage.activity.data) return true;
        else if (currentPackage.activity.isLoading) isLoading = true;
        
        return false;
    }).map(currentPackage => {
        const { id, name } = currentPackage;
        const data = currentPackage.activity.data.map(weekData => {
            const weekStart = parseInt(weekData.week, 10) * 1000;
            const weekStartLabel = moment(weekStart).format('MMM Do');
            const weekEndLabel = moment(weekStart).add(7, 'days').format('MMM Do');

            if (weekData.total > max) max = weekData.total;
            
            return {
                name: `${weekStartLabel} - ${weekEndLabel}`,
                value: weekData.total,
                index: 1,
            }
        });

        return { id, name, data };
    });

    return {
        packages: packagesActivityData,
        range: [16, 256],
        domain: [0, max],
        isLoading,
    }
}

class PackagesActivityChart extends PureComponent {
    renderTooltip = (props) => {
        const { active, payload, color } = props;

        if (active && payload && payload.length) {
            const packageData = payload[0];
            const data = packageData.payload;
            
            return (
                <div style={style.tooltipInner}>
                    <h4>Commits</h4>
                    <p>{data.name}</p>
                    <p style={{ color }}>{packageData.name}: {data.value}</p>
                </div>
            );
        }
    
        return null;
    }
    
    render() { 
        const { packages } = this.props;
        if (packages.length === 0) return null;
        
        const activityData = extractActivityData(packages);
        if (activityData.packages.length === 0) return null;
        const { range, domain } = activityData;
        const legendPayload = activityData.packages.map(currentPackage => ({ value: currentPackage.name, type: 'circle', id: currentPackage.id, color: getColorForString(currentPackage.id) }));
        
        return (
            <InformationWrapper title="Annual Commits" subtitle="Weekly Commits Volume"> 
                {activityData.packages.map((currentPackage, idx) => (
                    <ResponsiveContainer width="100%" height={60} key={currentPackage.id}>
                        <ScatterChart margin={{ top: 30, bottom: 0, right: 10, left: 10 }} >
                            <YAxis type="number" dataKey="index" name={currentPackage.name} hide tick={false} tickLine={false} axisLine={false} label={{ value: currentPackage.name, position: 'insideBottomLeft' }}/>
                            <ZAxis type="number" dataKey="value" domain={domain} range={range} />
                            <Tooltip cursor={false} color={getColorForString(currentPackage.id)} wrapperStyle={style.tooltip} content={this.renderTooltip} />
                            <Scatter data={currentPackage.data} fill={getColorForString(currentPackage.id)}/>
                        </ScatterChart>
                    </ResponsiveContainer>
                ))}

                <ResponsiveContainer width="100%" height={40}>
                    <Legend align="center" wrapperStyle={style.scatterLegend} payload={legendPayload} />
                </ResponsiveContainer>
            </InformationWrapper>
        );
    }
}

export default PackagesActivityChart;
