import {
    GET_PACKAGES_DOWNLOADS_STARTED,
    GET_PACKAGES_DOWNLOADS_SUCCESS,
    GET_PACKAGES_DOWNLOADS_FAIL,
} from '../../types/packages';
import npm from '../../../api/npm';

export default function getPackageDownloads(newPackage) {
    return async (dispatch, getState) => {
        const packages = [newPackage];
        dispatch({ type: GET_PACKAGES_DOWNLOADS_STARTED, packages })

        try {
            const apiCall = await npm.getPackagesDownloads([newPackage.name]);
            const data = await apiCall.json();
            
            if (apiCall.status !== 200) {
                dispatch({ type: GET_PACKAGES_DOWNLOADS_FAIL, packages, errors: [data] });
            } else {
                dispatch({ type: GET_PACKAGES_DOWNLOADS_SUCCESS, packages, data: { [newPackage.name]: data }});
                return data;
            }
        } catch (err) {
            dispatch({ type: GET_PACKAGES_DOWNLOADS_FAIL, packages, errors: [err] });
        }
    }
}