import {
    GET_PACKAGE_CONTRIBUTORS_STARTED,
    GET_PACKAGE_CONTRIBUTORS_SUCCESS,
    GET_PACKAGE_CONTRIBUTORS_FAIL,
} from '../../types/packages';
import github from '../../../api/github';
import * as parseLinkHeader from 'parse-link-header';


function getTotalFromHeaders(apiCall) {
    const linkHeader = apiCall.headers.get('Link');
 
    if (linkHeader) {
        const parsedLinkHeader = parseLinkHeader(linkHeader);
        if (parsedLinkHeader && parsedLinkHeader.last && parsedLinkHeader.last.page) {
            return parseInt(parsedLinkHeader.last.page, 10);
        }
    }

    return 0;
}

export default function getPackageContributors(packageId) {
    return async (dispatch, getState) => {
        dispatch({ type: GET_PACKAGE_CONTRIBUTORS_STARTED, packageId })

        try {
            const apiCall = await github.getRepositoryContributors(packageId);
            const data = await apiCall.json();
            let total = getTotalFromHeaders(apiCall);
            
            if (apiCall.status !== 200) {
                dispatch({ type: GET_PACKAGE_CONTRIBUTORS_FAIL, packageId, errors: [data] });
            } else {
                dispatch({ type: GET_PACKAGE_CONTRIBUTORS_SUCCESS, packageId, data, total });
            }
        } catch (err) {
            dispatch({ type: GET_PACKAGE_CONTRIBUTORS_FAIL, packageId, errors: [err] });
        }
    }
}