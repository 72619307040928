import React from 'react';
import PropTypes from 'prop-types';
import numberFormatter from '../../utils/formatters/number';

export default class Number extends React.Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
        ]),
    };

    render() {
        const { children } = this.props;
        const number = parseFloat(children);
        return isNaN(number) ? '-' : numberFormatter(number);
    }
}