import {
    GET_PACKAGE_COMMITS_STARTED,
    GET_PACKAGE_COMMITS_SUCCESS,
    GET_PACKAGE_COMMITS_FAIL,

} from '../../types/packages';

export default function getPackageCommits(state, action) {
    let currentPackageIndex;

    switch (action.type) {
        case GET_PACKAGE_COMMITS_STARTED:
            currentPackageIndex = state.findIndex(lookupPackage => lookupPackage.get('id') === action.packageId);
            
            if (currentPackageIndex >= 0) {
                return state.setIn([currentPackageIndex, 'commits', 'isLoading'], true);
            }

            return state;
        case GET_PACKAGE_COMMITS_SUCCESS:
            currentPackageIndex = state.findIndex(lookupPackage => lookupPackage.get('id') === action.packageId);
            
            if (currentPackageIndex >= 0) {
                const lastCommitDate = action.data.length > 0 ? new Date(action.data[0].commit.committer.date).getTime() : 0;
                return state.setIn([currentPackageIndex, 'commits', 'isLoading'], false)
                            .setIn([currentPackageIndex, 'commits', 'lastCommitDate'], lastCommitDate)
                            .setIn([currentPackageIndex, 'commits', 'isLoaded'], true)
                            .setIn([currentPackageIndex, 'commits', 'total'], action.total);
            }

            return state;
        case GET_PACKAGE_COMMITS_FAIL:
            currentPackageIndex = state.findIndex(lookupPackage => lookupPackage.get('id') === action.packageId);
            
            if (currentPackageIndex >= 0) {
                return state.setIn([currentPackageIndex, 'commits', 'isLoading'], false)
                            .setIn([currentPackageIndex, 'commits', 'errors'], action.errors);
            }

            return state;
        default:
            return state;
    }
}