import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as QueryString from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SearchForm from './SearchForm/SearchForm';
import Logo from './Logo';
import { getPackagesIdsFromQSParams  } from '../../utils';
import { withRouter } from 'react-router';


const styles = theme => ({
    root: {
        backgroundColor: '#fff',
        borderBottom: '1px solid #ccc',
    },
    searchFormContainer: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    }
});

class Header extends Component {
    addPackage = (packageId) => {
        try {
            let packages = getPackagesIdsFromQSParams(this.props);
            
            if (!packages.includes(packageId)) {
                packages.push(packageId.toLowerCase());
                packages = packages.sort();
            
                const search = `?${QueryString.stringify({ packages })}`;
                this.props.history.push({ pathname: 'report', search });
            }
        } catch (err) {
            console.error(err);
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <AppBar className={classes.root} position="sticky" elevation={0}>
                <Toolbar disableGutters={true} className={classes.searchFormContainer}>
                  <Logo />
                  <SearchForm  addPackage={this.addPackage}/>
                </Toolbar>
            </AppBar>
        );
    }
}

export default withRouter(connect()(withStyles(styles)(Header)));
