import {
    GET_PACKAGE_ACTIVITY_STARTED,
    GET_PACKAGE_ACTIVITY_SUCCESS,
    GET_PACKAGE_ACTIVITY_FAIL,
} from '../../types/packages';
import github from '../../../api/github';

export default function getPackagePulls(packageId) {
    return async (dispatch, getState) => {
        dispatch({ type: GET_PACKAGE_ACTIVITY_STARTED, packageId })

        try {
            const apiCall = await github.getRepositoryActivity(packageId);
            const data = await apiCall.json();
            
            if (apiCall.status !== 200) {
                dispatch({ type: GET_PACKAGE_ACTIVITY_FAIL, packageId, errors: [data] });
            } else {
                dispatch({ type: GET_PACKAGE_ACTIVITY_SUCCESS, packageId, data });
                return data;
            }
        } catch (err) {
            dispatch({ type: GET_PACKAGE_ACTIVITY_FAIL, packageId, errors: [err] });
        }
    }
}