import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { TableCell, TableRow } from '@material-ui/core';
import Number from '../Formatters/Number';

const styles = theme => ({
	link: {
		textDecoration: 'none',
		fontWeight: '500',
	},
    cell: {
		padding: theme.spacing.unit * 2,
    },
});

class PackagesGeneralTableRow extends React.Component {
	static propTypes = {
		package: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
	}

	render() {
		const {  classes, package: { id, name, created, stars, forks, watchers, license } } = this.props;

		return (
			<TableRow
				hover
				tabIndex={-1}
				key={id}
			>
				<TableCell className={classes.cell}>
					<a 
						rel="noopener noreferrer"
						className={classes.link} 
						target="_blank" 
						href={`https://github.com/${id}`}>
						{name.toUpperCase()}
					</a>
				</TableCell>
				<TableCell className={classes.cell}>
					{moment(created).fromNow(false)}
				</TableCell>
				<TableCell className={classes.cell}>
					{license}
				</TableCell>
				<TableCell className={classes.cell}>
					<Number>{stars}</Number>
				</TableCell>
				<TableCell className={classes.cell}>
					<Number>{watchers}</Number>
				</TableCell>
				<TableCell className={classes.cell}>
					<Number>{forks}</Number>
				</TableCell>
			</TableRow>
		);
	}
}

export default withStyles(styles)(PackagesGeneralTableRow);