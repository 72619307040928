import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { TableCell, TableRow } from '@material-ui/core';
import ContentLoader from '../ContentLoader';
import Number from '../Formatters/Number';

const styles = theme => ({
	link: {
		textDecoration: 'none',
		fontWeight: '500',
    },
    cell: {
        padding: theme.spacing.unit * 2,
    },
});

class PackagesActivityTableRow extends React.Component {
	static propTypes = {
		package: PropTypes.object.isRequired,
		classes: PropTypes.object.isRequired,
	}

	render() {
		const {  classes, package: { id, name, lastCommitDate, openIssues, totalIssues, contributors, commits, openPulls, totalPulls } } = this.props;

		return (
			<TableRow
				hover
				tabIndex={-1}
				key={id}
			>
                <TableCell className={classes.cell}>
                    <a 
                      rel="noopener noreferrer"
                      className={classes.link} 
                      target="_blank" 
                      href={`https://github.com/${id}`}>
                      {name.toUpperCase()}
                    </a>
                </TableCell>
                <TableCell className={classes.cell}>
                    <ContentLoader isLoading={lastCommitDate.isLoading}>
                        {moment(lastCommitDate.value).fromNow(false)}
                    </ContentLoader>
                </TableCell>
                <TableCell className={classes.cell}>
                    <ContentLoader isLoading={commits.isLoading}>
                        <Number>{commits.value}</Number>
                    </ContentLoader>
                </TableCell>
                <TableCell className={classes.cell}>
                    <ContentLoader isLoading={commits.isLoading}>
                        <Number>{contributors.value}</Number>
                    </ContentLoader>
                </TableCell>
                <TableCell className={classes.cell}>
                    <ContentLoader isLoading={openIssues.isLoading}>
                        <Number>{openIssues.value}</Number>
                    </ContentLoader>
                </TableCell>
                <TableCell className={classes.cell}>
                    <ContentLoader isLoading={totalIssues.isLoading}>
                        <Number>{totalIssues.value}</Number>
                    </ContentLoader>
                </TableCell>
                <TableCell className={classes.cell}>
                    <ContentLoader isLoading={openPulls.isLoading}>
                        <Number>{openPulls.value}</Number>
                    </ContentLoader>
                </TableCell>
                <TableCell className={classes.cell}>
                    <ContentLoader isLoading={totalPulls.isLoading}>
                        <Number>{totalPulls.value}</Number>
                    </ContentLoader>
                </TableCell>
			</TableRow>
		);
	}
}

export default withStyles(styles)(PackagesActivityTableRow);