import React from 'react';

const style = {
  input: {
    height: '36px',
    display: 'inline-block',
    borderRadius: '3px',
    width: '100%',
    outline: 'none',
    padding: '0 12px',
    fontSize: '16px',
    border: '1px solid #CCC',
    boxShadow: 'none',
    WebkitAppearance: 'none',
    transition: 'all ease-in-out 0.5s',
  }
};

export default function renderSearchInput(props) {
  const hasSuggestions = props['aria-expanded'];
  const inputStyle = { ...style.input };
  if (hasSuggestions) inputStyle.borderRadius = '3px 3px 0 0';

  return (
    <input 
      {...props}
      type="search" 
      placeholder="Paste GitHub URLs and press enter..." 
      style={inputStyle}
    />
  );
}