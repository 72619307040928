export const ADD_PACKAGES_SUCCESS = 'ADD_PACKAGES_SUCCESS';
export const ADD_PACKAGES_FAIL = 'ADD_PACKAGES_FAIL';
export const ADD_PACKAGES_STARTED = 'ADD_PACKAGES_STARTED';

export const ADD_PACKAGE_SUCCESS = 'ADD_PACKAGE_SUCCESS';
export const ADD_PACKAGE_FAIL = 'ADD_PACKAGE_FAIL';
export const ADD_PACKAGE_STARTED = 'ADD_PACKAGE_STARTED';

export const REMOVE_PACKAGE_SUCCESS = 'REMOVE_PACKAGE_SUCCESS';
export const REMOVE_PACKAGE_FAIL = 'REMOVE_PACKAGE_FAIL';
export const REMOVE_PACKAGE_STARTED = 'REMOVE_PACKAGE_STARTED';

export const GET_PACKAGE_GENERAL_DATA_STARTED = 'GET_PACKAGE_GENERAL_DATA_STARTED';
export const GET_PACKAGE_GENERAL_DATA_SUCCESS = 'GET_PACKAGE_GENERAL_DATA_SUCCESS';
export const GET_PACKAGE_GENERAL_DATA_FAIL = 'GET_PACKAGE_GENERAL_DATA_FAIL';

export const GET_PACKAGE_PULLS_STARTED = 'GET_PACKAGE_PULLS_STARTED';
export const GET_PACKAGE_PULLS_SUCCESS = 'GET_PACKAGE_PULLS_SUCCESS';
export const GET_PACKAGE_PULLS_FAIL = 'GET_PACKAGE_PULLS_FAIL';

export const GET_PACKAGE_COMMITS_STARTED = 'GET_PACKAGE_COMMITS_STARTED';
export const GET_PACKAGE_COMMITS_SUCCESS = 'GET_PACKAGE_COMMITS_SUCCESS';
export const GET_PACKAGE_COMMITS_FAIL = 'GET_PACKAGE_COMMITS_FAIL';

export const GET_PACKAGE_CONTRIBUTORS_STARTED = 'GET_PACKAGE_CONTRIBUTORS_STARTED';
export const GET_PACKAGE_CONTRIBUTORS_SUCCESS = 'GET_PACKAGE_CONTRIBUTORS_SUCCESS';
export const GET_PACKAGE_CONTRIBUTORS_FAIL = 'GET_PACKAGE_CONTRIBUTORS_FAIL';

export const GET_PACKAGE_ACTIVITY_STARTED = 'GET_PACKAGE_ACTIVITY_STARTED';
export const GET_PACKAGE_ACTIVITY_SUCCESS = 'GET_PACKAGE_ACTIVITY_SUCCESS';
export const GET_PACKAGE_ACTIVITY_FAIL = 'GET_PACKAGE_ACTIVITY_FAIL';

export const GET_PACKAGES_DOWNLOADS_STARTED = 'GET_PACKAGES_DOWNLOADS_STARTED';
export const GET_PACKAGES_DOWNLOADS_SUCCESS = 'GET_PACKAGES_DOWNLOADS_SUCCESS';
export const GET_PACKAGES_DOWNLOADS_FAIL = 'GET_PACKAGES_DOWNLOADS_FAIL';