import getRoundedScore from './getRoundedScore';

export default function getCommunityScore(currentPackage, packages) {
    let packagesTotalStars = 0;
    let packagesTotalWatchers = 0;
    let packagesTotalForks = 0;
    let packagesTotalContributors = 0;

    packages.filter(nextPackage => {
        return (nextPackage.general.isLoaded && nextPackage.contributors.isLoaded);
    }).forEach(nextPackage => {
        packagesTotalStars += nextPackage.general.data.stargazers_count;
        packagesTotalWatchers += nextPackage.general.data.subscribers_count;
        packagesTotalForks += nextPackage.general.data.forks;
        packagesTotalContributors += nextPackage.contributors.total;
    });
    
    const packageRelativeStars = currentPackage.general.data.stargazers_count / packagesTotalStars;
    const packageRelativeWatchers = currentPackage.general.data.subscribers_count / packagesTotalWatchers;
    const packageRelativeForks = currentPackage.general.data.forks / packagesTotalForks;
    const packageRelativeContributors = currentPackage.contributors.total / packagesTotalContributors;
    const averageScore = (packageRelativeStars * 0.3  + packageRelativeWatchers * 0.1 + packageRelativeForks * 0.1 + packageRelativeContributors * 0.6);
    const roundedScore = getRoundedScore(100 * averageScore, -1);
    
    return roundedScore;
}