import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import * as QueryString from 'query-string';
import Twitter from './Social/Twitter';
import jsonp from 'jsonp';

const LOCALSTORAGE_EMAIL_PROPERTY = 'subscription-email-sent';
const styles = theme => ({
	root: {
        borderTop: '1px solid #DDD',
        borderBottom: '1px solid #DDD',
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: 30,
        paddingBottom: 20,
    },
    form: {
        display: 'flex',
    },
    thankyou: {
        backgroundColor: theme.palette.primary.light,
        textAlign: 'center',
        borderRadius: 3,
        padding: 8,
    },
    thankyouText: {
        color: '#fff',
    },
    social: {
        marginTop: theme.spacing.unit * 2,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    text: {
        marginBottom: 10,
    },
	header: {
		margin: theme.spacing.unit * 4,
	},
    input: {
        ...theme.typography.button,
        textTransform: 'none',
        lineHeight: '1.4em',
        padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
        border: `1px solid ${
            theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'
        }`,
        boxSizing: 'border-box',
        borderRadius: 4,
        outline: 'none',
        verticalAlign: 'middle',
        flex: 1,
        marginRight: theme.spacing.unit,
    },
});

class EmailSubscription extends React.Component {
    state = {
        isValid: false,
        emailSent: JSON.parse(localStorage.getItem(LOCALSTORAGE_EMAIL_PROPERTY)),
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    handleSubmit = (e) => {
        // Stop form submision
        e.preventDefault();
        e.stopPropagation();


        // Create form url
        const formData = {};
        new FormData(e.target).forEach((value, key) => formData[key] = value);
        const url = 'https://gitcompare.us18.list-manage.com/subscribe/post-json?' + QueryString.stringify(formData);

        // Post form
        jsonp(url, { param: 'c' }, (err, data) => {
            localStorage.setItem(LOCALSTORAGE_EMAIL_PROPERTY, 'true');
        });
        
        // Set state
        this.setState({
            emailSent: true,
        });
    }

    unsubscribe = () => {
        localStorage.removeItem(LOCALSTORAGE_EMAIL_PROPERTY);
        
        // Set state
        this.setState({
            emailSent: false,
        });
    }

    handleChange = (e) => {
        const { validity: { valid } } = e.target;
        this.setState({
            isValid: valid,
        });
    }

    render() {
        const { isValid, emailSent } = this.state;
        const { classes } = this.props;

        return (
			<section>
                <Typography className={classes.header} variant="headline" align="center">
                    Subscribe
                </Typography>
                <Paper className={classes.root} elevation={0}>
                    <Typography className={classes.text} variant="caption" align="center" gutterBottom >
                        <span role="img" aria-label="smile">🤗</span> Dont miss out on our updates with new reports and tools <span role="img" aria-label="smile">🤗</span>
                    </Typography>

                    {!emailSent && 
                        <form action="https://gitcompare.us18.list-manage.com/subscribe/post-json?c=?" 
                          method="post" 
                          target="_blank" 
                          className={classes.form}
                          onSubmit={this.handleSubmit}>
                            <input type="hidden" name="u" value="5d5096fbf0445dbf3d387150e" />
                            <input type="hidden" name="id" value="627c3387af" />
                            <input type="email" placeholder="YOUR EMAIL ADDRESS" className={classes.input} onChange={this.handleChange} name="EMAIL" />
                            <Button type="submit" variant="outlined" color="primary" disabled={!isValid}>
                                Subscribe
                            </Button>
                        </form>
                    }
                    {emailSent && 
                        <div className={classes.thankyou}>
                            <Typography className={classes.thankyouText} variant="body1" align="center">
                                Thank you for subscribing!&nbsp;
                                <a onClick={this.unsubscribe} className={classes.thankyouText} rel="noopener noreferrer" target="_blank" href="https://gitcompare.us18.list-manage.com/unsubscribe?u=5d5096fbf0445dbf3d387150e&id=627c3387af">
                                    Unsubscribe
                                </a>
                            </Typography>
                        </div>
                    }
                    <section className={classes.social}>
                        <Twitter />
                    </section>
                </Paper>
			</section>
        );
    }
}

export default withStyles(styles)(EmailSubscription);