import {
    GET_PACKAGE_CONTRIBUTORS_STARTED,
    GET_PACKAGE_CONTRIBUTORS_SUCCESS,
    GET_PACKAGE_CONTRIBUTORS_FAIL,

} from '../../types/packages';

export default function getPackageContributors(state, action) {
    let currentPackageIndex;

    switch (action.type) {
        case GET_PACKAGE_CONTRIBUTORS_STARTED:
            currentPackageIndex = state.findIndex(lookupPackage => lookupPackage.get('id') === action.packageId);
            
            if (currentPackageIndex >= 0) {
                return state.setIn([currentPackageIndex, 'contributors', 'isLoading'], true);
            }

            return state;
        case GET_PACKAGE_CONTRIBUTORS_SUCCESS:
            currentPackageIndex = state.findIndex(lookupPackage => lookupPackage.get('id') === action.packageId);
            
            if (currentPackageIndex >= 0) {
                return state.setIn([currentPackageIndex, 'contributors', 'isLoading'], false)
                            .setIn([currentPackageIndex, 'contributors', 'isLoaded'], true)
                            .setIn([currentPackageIndex, 'contributors', 'total'], action.total);
            }

            return state;
        case GET_PACKAGE_CONTRIBUTORS_FAIL:
            currentPackageIndex = state.findIndex(lookupPackage => lookupPackage.get('id') === action.packageId);
            
            if (currentPackageIndex >= 0) {
                return state.setIn([currentPackageIndex, 'contributors', 'isLoading'], false)
                            .setIn([currentPackageIndex, 'contributors', 'errors'], action.errors);
            }

            return state;
        default:
            return state;
    }
}