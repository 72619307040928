import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import logo from '../../assets/img/logo.png';

const styles = theme => ({
    root: {
        marginRight: 8,
        lineHeight: 0,
    },
    img: {
        height: 36,
        width: 36,
    },
});

class Logo extends PureComponent {
    render() {
        const { classes } = this.props;

        return (
            <Link className={classes.root} to="/">
                <img className={classes.img} src={logo} alt="Compare" />
            </Link>
        );
    }
}

export default withStyles(styles)(Logo);
