import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

const styles = theme => ({
	root: {
        padding: theme.spacing.unit,
        textAlign: 'center',
    }
});

class GridLink extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        to: PropTypes.object.isRequired,
    };

    render() {
        const { classes, to } = this.props;

        return (
          <Grid container className={classes.root}>
            <Button to={to} component={Link} fullWidth variant="text" color="primary">
                More Reports
            </Button>
          </Grid>
        );
    }
}

export default withStyles(styles)(GridLink);