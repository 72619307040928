import {
    GET_PACKAGES_DOWNLOADS_STARTED,
    GET_PACKAGES_DOWNLOADS_SUCCESS,
    GET_PACKAGES_DOWNLOADS_FAIL,
} from '../../types/packages';

export default function getPackagesDownloads(state, action) {
    let currentPackageIndex, newState;

    switch (action.type) {
        case GET_PACKAGES_DOWNLOADS_STARTED:
            newState = state;

            action.packages.forEach(currentPackage => {
                currentPackageIndex = newState.findIndex(lookupPackage => lookupPackage.get('id') === currentPackage.id);
                
                if (currentPackageIndex >= 0) {
                    newState = newState.setIn([currentPackageIndex, 'downloads', 'isLoading'], true);
                }
            });

            return newState;
        case GET_PACKAGES_DOWNLOADS_SUCCESS:
            newState = state;

            action.packages.forEach(currentPackage => {
                currentPackageIndex = newState.findIndex(lookupPackage => lookupPackage.get('id') === currentPackage.id);
                
                if (currentPackageIndex >= 0) {
                    newState = newState.setIn([currentPackageIndex, 'downloads', 'isLoading'], false)
                                       .setIn([currentPackageIndex, 'downloads', 'isLoaded'], true)
                                       .setIn([currentPackageIndex, 'downloads', 'data'], action.data[currentPackage.name].downloads);
                }
            });

            return newState;
        case GET_PACKAGES_DOWNLOADS_FAIL:
            newState = state;

            action.packages.forEach(currentPackage => {
                currentPackageIndex = newState.findIndex(lookupPackage => lookupPackage.get('id') === currentPackage.id);
                
                if (currentPackageIndex >= 0) {
                    newState = newState.setIn([currentPackageIndex, 'downloads', 'isLoading'], false)
                                       .setIn([currentPackageIndex, 'downloads', 'isLoaded'], true)
                                       .setIn([currentPackageIndex, 'downloads', 'errors'], action.errors);
                }
            });

            return newState;
        default:
            return state;
    }
}