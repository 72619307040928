import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { withStyles } from '@material-ui/core/styles';
import renderSuggestion from './renderSuggestion';
import renderSuggestions from './renderSuggestions';
import renderSearchInput from './renderSearchInput';
import github from '../../../api/github';

const styles = theme => ({
    container: {
        flexGrow: 1,
        position: 'relative',
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
});

class AutoComplete extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        createInputRef: PropTypes.func.isRequired,
    };

    state = {
      suggestions: [],
    };
  
    handleSuggestionsFetchRequested = ({ value }) => {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);

      this.searchTimeout = setTimeout(async () => {
        try {
          const apiCall = await github.searchRepositories(value);
          
          if (apiCall.status === 200) {
            const data = await apiCall.json();
            const suggestions = data.items ? data.items.slice(0, 5) : [];
            this.setState({ suggestions });
          } else {
            this.setState({ suggestions: [] });
          }
        } catch(err) {
          this.setState({ suggestions: [] });
        }
      }, 200);
    };
  
    handleSuggestionsClearRequested = () => {
      this.setState({
        suggestions: [],
      });
    };

    shouldRenderSuggestions = (value) => {
      return value.length >= 3;
    }
  
    render() {
      const { searchQuery, classes, createInputRef, handleSelection, setSearchQuery } = this.props;
      const { suggestions } = this.state;
  
      return (
        <Autosuggest
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderInputComponent={renderSearchInput}
          suggestions={suggestions}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          renderSuggestionsContainer={renderSuggestions}
          getSuggestionValue={(suggestion) => suggestion.full_name}
          renderSuggestion={renderSuggestion}
          onSuggestionSelected={handleSelection}
          ref={createInputRef}
          inputProps={{
            value: searchQuery,
            onChange: setSearchQuery,
          }}
        />
      );
    }
}

export default withStyles(styles)(AutoComplete);