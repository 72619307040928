import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

export default function renderSuggestion(suggestion, { query, isHighlighted }) {
    return (
        <MenuItem selected={isHighlighted} component="div">
            <Avatar alt={suggestion.name} src={suggestion.owner.avatar_url} />
            <ListItemText primary={suggestion.name} secondary={suggestion.description} />
        </MenuItem>
    );
}